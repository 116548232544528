import { Download } from "@mui/icons-material";
import { Button } from "@mui/material";

const DownloadButton = ({ fileUrl, fileName }) => {
   
    const handleDownload = () => {
      if (fileUrl) {
        fetch(fileUrl)
          .then((response) => response.blob())
          .then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = fileName; // Optional: Customize filename
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url); // Clean up
          })
          .catch((error) => console.error('Error downloading the file:', error));
      } else {
        console.error('No file URL found');
      }
    };
  
    return (
      <Button
        variant="contained"
        color="primary"
        onClick={handleDownload}
      >
      <Download />
        Download
      </Button>
    );
  };
  
  export default DownloadButton;