import React from "react";
import { Modal } from "@mui/material";
import { studentFileDownloadPath } from "../../../../utils/studentFileDownloadUtil";
import DownloadButton from "../../../../utils/DownloadButton";

const isPDF = (fileName) => {
  return fileName && fileName.toLowerCase().endsWith(".pdf");
};

const styleIframe = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
  width: "100%",
};

const FileIframeViewModal = ({ open, onClose, selectedFileName, visa }) => (
  <Modal
    sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
    open={open}
    onClose={onClose}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
    <div style={{ background: "#fff", width: "70%", height: "70%", outline:"none" }}>
      {isPDF(selectedFileName?.fileName) ? (
        <div style={styleIframe}>
          <h1>This file type cannot be viewed directly in the browser.</h1>
          <DownloadButton
            fileName={selectedFileName?.fileName}
            fileUrl={`${studentFileDownloadPath(visa?.studentId, selectedFileName?.fileName)}`}
          />
        </div>
      ) : (
        <>
          <iframe
            src={`${studentFileDownloadPath(visa?.studentId, selectedFileName?.fileName)}`}
            style={{
              background: "white",
              border: "none",
              outline: "none",

            }}

            height="100%"
            width="100%"
            title="pdf Viewer"
          ></iframe>
          <div style={{ display: "flex", justifyContent: "end", alignItems: "center", outline:"none" }}>
            <DownloadButton
              fileName={selectedFileName?.fileName}
              fileUrl={studentFileDownloadPath(visa?.studentId, selectedFileName?.fileName)}
            />
          </div>
        </>
      )}
    </div>
  </Modal>
);

export default FileIframeViewModal;

