import React from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Checkbox, Button, Tooltip } from "@mui/material";
import { Visibility, Replay as ReplayIcon } from "@mui/icons-material";
import SpeakerNotesIcon from "@mui/icons-material/SpeakerNotes";
import {
  canView,
  canVerify,
  canRevert,
  canUpload,
  showAllOptionsIfNotUploaded,
} from "../../../../BussinessLogic/Visa/VisaDocumentAccessChecker";

const DocumentTable = ({ documents,visa, visaDocs, handleView, handleRevert, handleVerify, handleFileUpload, handleFileUpdate, handleVisaDocuments }) => {


 const handleVisaDocumentsFieldDisable = () => {
    if(visa?.visaDocuments?.completed === true){
      return true
    }
    return false
  }
  const columns = [
    {
      id: "document",
      label: "Document",
      accessor: (row, isFirstInstance, noFileUploaded) => (isFirstInstance || noFileUploaded ? row.fieldTitle : ""),
    },
    {
      id: "fileName",
      label: "File Name",
      accessor: (row) => row.fileName || "",
    },
    {
      id: "view",
      label: "View",
      accessor: (row) =>
        canView(row) || showAllOptionsIfNotUploaded(row) ? (
          <Tooltip
            title={row.revert?.value ? "Document Is Reverted" : !row.viewed?.value ? "View Document" : "Document Viewed"}
            placement="top"
          >
            <span
            onClick={() => {
              if (row.fileName && !row.revert?.value) {
                handleView(row);
              }
            }} >
              <Visibility
               
                style={{ color: row.viewed?.value ? "green" : "inherit", cursor:"pointer" }}
              />
            </span>
          </Tooltip>
        ) : null,
    },
    {
      id: "verify",
      label: "Verify",
      accessor: (row) =>
        canVerify(row) || showAllOptionsIfNotUploaded(row) ? (
          <Tooltip
            title={!row.viewed?.value ? "View Document Then Verify" : row.verify?.value ? "Document Verified" : "Verify Document"}
            placement="top"
          >
            <span>
              <Checkbox
                onChange={() => handleVerify(row)}
                disabled={!row.viewed?.value || row.revert?.value  || handleVisaDocumentsFieldDisable()}
                checked={!!row.verify?.value}
                style={{cursor:"pointer" }}
              />
            </span>
          </Tooltip>
        ) : null,
    },
    {
      id: "revert",
      label: "Revert",
      accessor: (row) =>
        canRevert(row) || showAllOptionsIfNotUploaded(row) ? (
          <Tooltip
            title={
              row.verify?.value
                ? "UnVerify Document Then Revert"
                : !row.viewed?.value
                ? "View Document Then Revert"
                : row.revert?.value
                ? "Document Reverted"
                : "Revert Document"
            }
            placement="top"
          >
            <span>
              <ReplayIcon
                onClick={() => {
                  if (row.viewed?.value && !row.verify?.value && !handleVisaDocumentsFieldDisable()) {
                    handleRevert(row);
                  }
                }}
                style={{
                  color: row.verify?.value  ? "grey" : row.revert?.value ? "red" : "inherit",
                  transform: row.revert?.value ? "rotate(270deg)" : "none",
                }}
              />
            </span>
          </Tooltip>
        ) : row.revert?.value ? (
          <SpeakerNotesIcon  titleAccess={row?.revert?.comment} color="gray" />
        ) : null,
    },
    {
      id: "upload",
      label: "Upload",
      accessor: (row, isFirstInstance, noFileUploaded) => {
        // Determine if we should show the upload button
        const shouldShowUploadButton =
          isFirstInstance || 
          row.revert?.value || 
          (!row.fileName && noFileUploaded); 

        return shouldShowUploadButton && (canUpload(row) || showAllOptionsIfNotUploaded(row)) ? (
          <Button variant="contained" component="label"   disabled={handleVisaDocumentsFieldDisable()}>
            {row.revert?.value ? "Replace File" : !row.fileName? "Upload": "Add Files"}
            <input
              type="file"
              hidden
              onChange={(event) =>
                row.revert?.value
                  ? handleFileUpdate(event, row?.fieldName, row.id) // Pass the ID here
                  : handleFileUpload(event, row.fieldName)
              }
            
            />
          </Button>
        ) : null;
      },
    },
  ];

  const data = [];
  const shownDocuments = new Set();

  for (let doc of documents) {
    if (!visaDocs || !visaDocs[doc.fieldName]) {
      data.push({ fieldName: doc.fieldName, fieldTitle: doc.title, noFileUploaded: true });
    } else {
      for (let docElement of visaDocs[doc.fieldName]) {
        const isFirstInstance = !shownDocuments.has(doc.fieldName);
        const noFileUploaded = !docElement.fileName;
        if (isFirstInstance) shownDocuments.add(doc.fieldName);
        data.push({ fieldName: doc.fieldName, fieldTitle: doc.title, isFirstInstance, noFileUploaded, ...docElement });
      }
    }
  }
    // Check if all documents are verified
    const allVerified = data.every((row) => row.verify?.value);

  return (
    <TableContainer component={Paper}>
      <Table aria-label="document table">
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell key={column.id}>{column.label}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <TableRow key={index}>
              {columns.map((column) => (
                <TableCell key={column.id}>{column.accessor(row, row.isFirstInstance, row.noFileUploaded)}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      
      {allVerified && (
    <div style={{ display: 'flex', justifyContent: 'flex-end', margin: '16px', paddingRight:'90px' }}>
      <Button variant="contained" 
      onClick={handleVisaDocuments}
      disabled={handleVisaDocumentsFieldDisable()}
      >Proceed</Button>
    </div>
  )}
    </TableContainer>
  );
};

export default DocumentTable;
