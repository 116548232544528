import { getLoggedInUser } from "../../utils/loggedInUser";

const loggedInUser = getLoggedInUser();

export const isAdministrator = loggedInUser?.role === "Administrator";

export const isUserUploader = (uploadedUserEmail) => {
  if (isAdministrator) return true;

  return loggedInUser?.email === uploadedUserEmail;
};

const isDocumentView = (UserEmail) => {
  if (isAdministrator) return true;
  return loggedInUser?.email !== UserEmail;
};

export const canView = (row) => {
  return row.fileName && isDocumentView(row.uploaded?.user.email);
};

export const canVerify = (row) => {
  return row.fileName && isDocumentView(row.uploaded?.user.email);
};

export const canRevert = (row) => {
  return row.fileName && isDocumentView(row.uploaded?.user.email);
};

export const canUpload = (row) => {
  if (isAdministrator) return true;

  return row.fileName && isUserUploader(row.uploaded?.user.email);
};

export const showAllOptionsIfNotUploaded = (row) => {
  return !row.fileName;
};
