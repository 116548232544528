import React, { useState } from "react";
import { Box, Typography, Button, Grid, Card, CardContent, CardHeader, IconButton, Alert, Tooltip } from "@mui/material";
import { UploadFile as UploadFileIcon, Visibility as ViewIcon, CloudUpload as CloudUploadIcon } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { getLoggedInUser } from "../../../../utils/loggedInUser";
import FileIframeViewModal from "../DocumentCheckList/FileIFrameView";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
  
});

const DocumentUploadBox = styled(Box)(({ theme }) => ({
  border: `2px dashed ${theme.palette.primary.light}`,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(3),
  textAlign: "center",
  transition: "all 0.3s ease",
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
    borderColor: theme.palette.primary.main,
  },
}));

const DocumentVerification = ({ updateVisa, visa, visaFileUploading, profFileName }) => {
//   console.log("visa", visa);
  const [selectedFile, setSelectedFile] = useState(null);
  const [viewModel, setViewModel] = useState(false);
  const [error, setError] = useState(null);
  const user = getLoggedInUser();

  if (profFileName === "visaDocumentsProf") {
    var VisaProProfFile = visa?.visaDocumentsProf;
  } else if (profFileName === "visaDocumentsProf2") {
    var VisaProProfFile = visa?.visaDocumentsProf2;
  }

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    // File type validation
    const allowedTypes = ["image/png", "image/jpeg", "application/pdf"];
    if (file) {
      if (!allowedTypes.includes(file.type)) {
        setError("Invalid file type. Please upload PNG, JPG, or PDF.");
        return;
      }

      setSelectedFile(file);
      setError(null);
    }
  };

  const handleSubmit = () => {
    if (!selectedFile) {
      setError("Please select a file before submitting.");
      return;
    }

    try {
      visaFileUploading(selectedFile);

      const fileDetails = {
        fileName: selectedFile.name,
        submittedBy: {
          id: Date.now().toString(),
          user: { id: user?._id, name: user?.name, email: user?.email },
          uploadedAt: new Date(),
        },
      };

    // if (VisaProProfFile?.fileName) {
        // here you can write code to update the file to replace
    // } else {
      updateVisa({ ...visa, [profFileName]: fileDetails }, visa._id);
      setSelectedFile(null);
    // }
    } catch (err) {
      setError("Failed to upload file. Please try again.");
    }
  };

  return (
    <>
      <Card
        sx={{
          maxWidth: 600,
          margin: "auto",
          mt: 5,
          borderRadius: 3,
          boxShadow: "0 8px 24px rgba(0,0,0,0.12)",
        }}
        elevation={4}
      >
        <CardHeader
          title={`${profFileName === "visaDocumentsProf" ? "" : "2nd"} Document Verification Proof`}
          titleTypographyProps={{
            variant: "h5",
            color: "primary",
            sx: { fontWeight: 600 },
          }}
          sx={{
            backgroundColor: "rgba(0,0,0,0.02)",
            borderBottom: "1px solid rgba(0,0,0,0.1)",
          }}
        />
        <CardContent>
          {error && (
            <Alert severity="error" onClose={() => setError(null)} sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <DocumentUploadBox>
                <Button component="label" variant="outlined" startIcon={<CloudUploadIcon />} color="primary" sx={{ mb: 2 }} disabled={VisaProProfFile?.fileName}>
                  Choose File
                  <VisuallyHiddenInput type="file" accept=".png, .jpg, .jpeg, .pdf" onChange={handleFileChange}  />
                </Button>
                <Typography variant="body2" color="textSecondary">
                  {selectedFile ? `Selected: ${selectedFile.name}` : "PNG, JPG, or PDF (Max 5MB)"}
                </Typography>
              </DocumentUploadBox>
            </Grid>

            {(selectedFile || VisaProProfFile?.fileName) && (
              <Grid item xs={12} container spacing={2}>
                {VisaProProfFile?.fileName && (
                  <Grid item xs={12}>
                    <Tooltip title="View Uploaded Document">
                      <Button variant="outlined" color="primary" startIcon={<ViewIcon />} onClick={() => setViewModel(true)} fullWidth>
                        View File
                      </Button>
                    </Tooltip>
                  </Grid>
                )}
                {!VisaProProfFile?.fileName ? (
                <Grid item xs={12}>
                  <Button variant="contained" color="primary" startIcon={<UploadFileIcon />} onClick={handleSubmit} fullWidth>
                    {VisaProProfFile?.fileName ? "Replace Document" : "Submit Document"}
                  </Button>
                </Grid>
                ) : null}
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>

      <FileIframeViewModal open={viewModel} onClose={() => setViewModel(false)} selectedFileName={VisaProProfFile} visa={visa} />
    </>
  );
};

export default DocumentVerification;
