import { Avatar, Box, Button, Divider, Stack, Typography, MenuItem, Select, IconButton, Menu } from "@mui/material";
import React, { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { getLoggedInUser } from "../../../utils/loggedInUser";
import { loadUserOptions } from "../../../BussinessLogic/Visa/VisaAssignTo";

const VisaTopDetail = ({ visaView, users, updateVisa }) => {
  //   console.log("users", users);
  //   console.log("visaView", visaView);
  // console.log(student);
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOptionClick = (user) => {
    updateVisa && updateVisa({ visaOfficer: { id: user._id, name: user.name, email: user.email } }, visaView._id);
    // setAnchorEl(null);
    handleClose();
  };

  return (
    <Stack
      direction={"row"}
      justifyContent={"space-between"}
      flexWrap={"wrap"}
      alignItems={"center"}
      mx={4}
      sx={{
        backgroundColor: "primary.main",
        p: "25px 30px",
        mb: 6,
        gap: "20px",

        // add media query
        ["@media (max-width: 560px)"]: {
          mx: 0,
        },
      }}
    >
      <Stack direction={"row"} alignItems={"center"} spacing={1} color={"white"}>
        <Avatar alt="Profile Image" src="" sx={{ width: 50, height: 50 }} />
        <Stack direction={"column"}>
          <Typography variant={"h5"}>{visaView?.studentName}</Typography>
          <Typography
            sx={{
              fontSize: 13,
              fontWeight: "light",
            }}
          >
            #{visaView?.studentId}
          </Typography>
        </Stack>
      </Stack>

      <Stack direction={"row"} alignItems={"center"} spacing={1} color={"white"}>
        <Stack direction={"column"}>
          <Typography variant={"h5"}>Application Id #</Typography>
          <Typography
            sx={{
              fontSize: 13,
              fontWeight: "light",
            }}
          >
            {visaView?.applicationId}
          </Typography>
        </Stack>
      </Stack>
      <Stack direction={"column"}>
        <Typography variant={"h5"} color={"white"}>Country</Typography>
        <Typography
          color={"white"}
          sx={{
            fontSize: 13,
            fontWeight: "light",
          }}
        >
          {visaView?.country}
        </Typography>
      </Stack>
      <Stack direction={"column"}>
        <Typography variant={"h5"} sx={{ color: "white" }}>
          Branch
        </Typography>
        <Typography variant={"subtitle2"} sx={{ color: "white", pt: 0.8 }}>
          {visaView?.branch ? visaView?.branch : "N/A"}
        </Typography>
      </Stack>
      <Stack direction={"column"} color={"white"}>
        <Typography variant={"h5"} sx={{ color: "white" }}>
          Visa Officer
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography variant={"subtitle2"} sx={{ color: "white" }}>
            {/* demo visa officer name */}
            {visaView?.visaOfficer?.name}
          </Typography>
          <Box
            onClick={handleClick}
            sx={{
              pt: 1,

              cursor: "pointer",
            }}
          >
            <ExpandMoreIcon color="white" />
          </Box>

          <Menu
            id="icon-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            PaperProps={{ style: { maxHeight: "160px" } }}
          >
            {loadUserOptions((user) => user.role === "Visa Officer", users, handleOptionClick)}
          </Menu>
        </Box>
      </Stack>
    </Stack>
  );
};

export default VisaTopDetail;
