import { Modal, Box, TextField, Button, FormControl, InputLabel, Select, MenuItem } from "@mui/material";

const RevertModal = ({ open, onClose, revertMessage, setRevertMessage, submitRevert }) => {

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 10,
          p: 3,
          width: 400,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {/* <FormControl sx={{ width: "100%", mb: 5 }}>
          <InputLabel id="demo-simple-select-label">Assigned To</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={assignTo}
            label="Assigned To"
            onChange={(e) => setAssignTo(e.target.value)}
          >
            <MenuItem value={visa?.visaOfficer?.name}>{visa?.visaOfficer?.name || "N.A"} </MenuItem>
            <MenuItem value={visa?.counselor?.name}>{visa?.counselor?.name}</MenuItem>
          </Select>
        </FormControl> */}

        <FormControl sx={{ width: "100%", mb: 5 }}>
          <TextField
            fullWidth
            id="outlined-basic"
            label="Comment"
            variant="outlined"
            rows={4}
            required
            multiline
            value={revertMessage}
            onChange={(e) => setRevertMessage(e.target.value)}
          />
        </FormControl>

        <Box sx={{ display: "flex", justifyContent: "space-between", mt: 5, width: "70%" }}>
          <Button variant="contained" color="primary" onClick={submitRevert}>
            Submit
          </Button>
          <Button variant="contained" color="primary" onClick={onClose}>
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default RevertModal;
