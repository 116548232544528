import {
  Box,
  Button,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextareaAutosize,
  TextField,
  Typography,
  Tooltip,
  Checkbox,
  Snackbar,
  MenuItem,
} from "@mui/material";
import React, { useState } from "react";
import ScheduleForm from "./ScheduleForm";
import MuiAlert from "@mui/material/Alert";
import { getLoggedInUser } from "../../../../utils/loggedInUser";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 10,
  p: 4,
};

const Schedule = ({ updateVisa, visa }) => {
  // console.log("visa", visa);
  const [scheduleMeeting, setScheduleMeeting] = useState("");
  const [open, setOpen] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [text, setText] = useState("");
  const [currentLeadData, setCurrentLeadData] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [message, setMessage] = useState("");
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleClose3 = () => setOpen3(false);
  const handleOpen3 = () => setOpen3(true);

  const handleChange = (e) => setText(e.target.value);


  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const user = getLoggedInUser();

  // set the value into updated visa object inside visaScheduleMeeting
  const handleMeetingChange = (e) => {
    setScheduleMeeting(e.target.value);

    if (e.target.value === "No") {
      const updatedData = {
        NeedsSchedule: e.target.value,
        Proceed: true,
        proceedBy: { user: { id: user?._id, name: user?.name, email: user?.email }, date: new Date() },
      };
      updateVisa({ ...visa, visaScheduleMeeting: updatedData });
    } else {
      const updatedData = {
        NeedsSchedule: e.target.value,
        Proceed: false,
      };
      updateVisa({ ...visa, visaScheduleMeeting: updatedData });
    }
  };

  const addScheduleToVisa = (newSchedule) => {
    try {
      // Combine the date and time fields into a single Date object
      const selectedDateTime = new Date(newSchedule.date + "T" + newSchedule.time);
      const currentDateTime = new Date();

      // Set seconds and milliseconds to zero for accurate time comparison
      selectedDateTime.setSeconds(0, 0);
      currentDateTime.setSeconds(0, 0);

      // Check if the selected date and time are in the future
      if (selectedDateTime >= currentDateTime) {
        // Proceed with adding the schedule if the time is valid
        const randomKey = Math.floor(Math.random() * 10000);
        const updatedData = {
          schedule: {
            ...visa.visaScheduleMeeting.schedule,
            [randomKey]: newSchedule,
          },
        };
        updateVisa({
          ...visa,
          visaScheduleMeeting: updatedData,
        });
        setMessage("Schedule Added Successfully");
        setSnackbarOpen(true);
      } else {
        setMessage("Cannot schedule a call for the Past Time");
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error("Error updating schedule:", error);
    }
  };

  const doneCall = (attendance, leadId, schedule, key, comment) => {
    console.log("keyyy", key);
    const updatedSchedule = {
      ...schedule,
      callDone: attendance === "Yes" ? true : false,
      mode: attendance === "No" ? "" : schedule.mode,
      comment: comment || schedule.comment,
    };

    const updatedVisa = {
      ...visa,
      visaScheduleMeeting: {
        ...visa.visaScheduleMeeting,
        schedule: {
          ...visa.visaScheduleMeeting.schedule,
          [key]: updatedSchedule,
        },
      },
    };
    updateVisa(updatedVisa);
  };


  const handleProceed = () => { 
    const allCallsDone = Object.values(visa?.visaScheduleMeeting?.schedule || {}).every(
      (schedule) => schedule.callDone !== undefined,
    );
    if (allCallsDone) {
      updateVisa({
        ...visa,
        visaScheduleMeeting: {
          ...visa.visaScheduleMeeting,
          Proceed: true,
          proceedBy: { user: { id: user?._id, name: user?.name, email: user?.email }, date: new Date() },
        },
      });
    } else {
      setMessage("Please complete all scheduled calls before proceeding");
      setSnackbarOpen(true);
    }
  };

  const tableColumns = [
    { id: "date", label: "Date" },
    { id: "time", label: "Time" },
    { id: "mode", label: "Mode" },
    { id: "scheduleComment", label: "Schedule Comment" },
    { id: "Attended", label: "Attended" },
  ];

  return (
    <React.Fragment>
      <Box sx={{ padding: 5, borderRadius: 2 }}>
        {/*  dropdown to ask the user do you want to schedule the meeting or not  */}

        {visa?.visaScheduleMeeting?.NeedsSchedule !== "Yes" && (
          <TextField
            select
            label="Do you want to schedule the meeting?"
            variant="outlined"
            value={scheduleMeeting}
            onChange={handleMeetingChange}
            // disabled={visa?.visaScheduleMeeting?.Proceed === true}
            fullWidth
          >
            <MenuItem value="Yes">Yes</MenuItem>
            <MenuItem value="No">No</MenuItem>
          </TextField>
        )}

        {/* if the user  Not selected Yes then show the schedule form  */}

        {visa?.visaScheduleMeeting?.NeedsSchedule === "Yes" && (
          <Box>
            <Box sx={{ display: "flex", justifyContent: "flex-end", mb: ".5rem" }}>
              {!open && (
                <Button variant="contained" onClick={handleOpen}
                 disabled={visa?.visaScheduleMeeting?.Proceed === true}
                 >
                  Add Schedule
                </Button>
              )}
            </Box>

            {open ? (
              <Box>
                <ScheduleForm handleClose={handleClose} addSchedule={addScheduleToVisa} />
              </Box>
            ) : (
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {tableColumns.map((column) => (
                        <TableCell
                          key={column.id}
                          sx={{ fontWeight: "bold", textTransform: "uppercase", backgroundColor: "rgba(25, 104, 173, 1)", color: "#fff" }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.entries(visa?.visaScheduleMeeting?.schedule || {}).map(([key, schedule], index) => (
                      <TableRow key={key}>
                        {tableColumns.map((column) => (
                          <TableCell key={column.id}>
                            {column.id === "Attended" ? (
                              <Box sx={{ display: "flex", gap: "1rem" }}>
                                {schedule.callDone === undefined || schedule.callDone === true ? (
                                  <Typography
                                    sx={{
                                      cursor: "pointer",
                                      color: schedule.callDone ? "blue" : "black",
                                      pointerEvents: schedule.callDone ? "none" : "auto",
                                    }}
                                    onClick={() => {
                                      setCurrentLeadData({ bool: true, id: visa._id, value: schedule, key });
                                      handleOpen3();
                                    }}
                                  >
                                    Yes
                                  </Typography>
                                ) : null}

                                {schedule.callDone === false || schedule.callDone === undefined ? (
                                  <Typography
                                    sx={{
                                      cursor: "pointer",
                                      color: schedule.callDone === false ? "red" : "black",
                                      pointerEvents: schedule.callDone === false ? "none" : "auto",
                                    }}
                                   onClick={() => {
                                      setCurrentLeadData({ bool: false, id: visa._id, value: schedule, key });
                                      handleOpen3();
                                    }
                                  }
                                  >
                                    No
                                  </Typography>
                                ) : null}
                              </Box>
                            ) : column.id === "scheduleComment" ? (
                              schedule.comment || "No Comment"
                            ) : (
                              schedule[column.id]
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>

                {Object.values(visa?.visaScheduleMeeting?.schedule || {}).length  > 0 && (
                  <Box sx={{ display: "flex", justifyContent: "flex-end", m: 2 }}>
                    <Button
                      variant="contained"
                      onClick={handleProceed}
                      disabled={visa?.visaScheduleMeeting?.Proceed === true}
                      // disabled={Object.values(visa?.visaScheduleMeeting?.schedule || {}).some((schedule) => schedule.callDone === undefined)}
                    >
                      Proceed
                    </Button>
                  </Box>
                )}
              </TableContainer>
            )}
          </Box>
        )}
        <Modal
          open={open3}
          onClose={handleClose3}
          hideBackdrop
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Comment
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <TextField
                fullWidth
                multiline
                label="Comment"
                InputProps={{ inputComponent: TextareaAutosize, rows: 5 }}
                value={text}
                onChange={handleChange}
              />
            </Typography>
            <Box sx={{ mt: 2 }}>
              <Button
                variant="contained"
                sx={{ mr: 2 }}
                onClick={() => {
                  doneCall(currentLeadData.bool ? "Yes" : "No", currentLeadData.id, currentLeadData.value, currentLeadData.key, text);
                  handleClose3();
                  setText("");
                }}
              >
                Submit
              </Button>
              <Button
                variant="outlined"
                onClick={() => {
                  handleClose3();
                  setText("");
                }}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Modal>
      </Box>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MuiAlert variant="filled" onClose={handleCloseSnackbar} severity="success">
          {message}
        </MuiAlert>
      </Snackbar>
    </React.Fragment>
  );
};

export default Schedule;
