import { useState } from "react";
import { checkCountry } from "../../../../BussinessLogic/Visa/CheckCountry";
import { getLoggedInUser } from "../../../../utils/loggedInUser";

export function useDocumentCheckListViewModel(visa, updateVisa, visaFileUploading) {
  // console.log("visa", visa);
  const [openViewModal, setOpenViewModal] = useState(false);
  const [openRevertModal, setOpenRevertModal] = useState(false);
  const [revertMessage, setRevertMessage] = useState("");
  // const [assignTo, setAssignTo] = useState("");
  const [selectedFileName, setSelectedFileName] = useState("");

  const documentChecklistName = checkCountry(visa);
  const user = getLoggedInUser();

  const handleView = (file) => {
    // console.log('file', file);
    setSelectedFileName(file);
    setOpenViewModal(true);
    const updatedVisaDocs = { ...visa.visaDocs };
    updatedVisaDocs[file.fieldName] = updatedVisaDocs[file.fieldName] || [];
    const doc = updatedVisaDocs[file.fieldName].find((doc) => doc.fileName === file.fileName);
    if (doc) {
      doc.viewed = {
        user: { id: user?._id, name: user?.name, email: user?.email },
        value: true,
      };
    }

    updateVisa({ visaDocs: updatedVisaDocs }, visa._id);
  };

  const handleRevert = (file) => {
    // console.log("File to revert handleRevert:", file);
    setOpenRevertModal(true);
    setSelectedFileName(file);
  };

  const handleVerify = (row) => {
    const updatedVisaDocs = { ...visa.visaDocs };
    updatedVisaDocs[row.fieldName] = updatedVisaDocs[row.fieldName] || [];
    const doc = updatedVisaDocs[row.fieldName].find((doc) => doc.fileName === row.fileName);

    if (doc) {
      // Toggle the `verify.value` property
      doc.verify = {
        user: { id: user?._id, name: user?.name, email: user?.email },
        value: !doc.verify?.value, // toggle between true and false
      };
    }

    updateVisa({ visaDocs: updatedVisaDocs }, visa._id);
  };

  const handleFileUpload = (file, fieldName) => {
    console.log("file upload function run ", file);
    const fileData = file?.target?.files[0];
    if (!fileData) return;

    visaFileUploading(fileData);

    const updatedVisaDocs = { ...visa.visaDocs };
    if (!updatedVisaDocs[fieldName]) updatedVisaDocs[fieldName] = [];
    updatedVisaDocs[fieldName].push({
      id: Date.now().toString(),
      fileName: fileData.name,
      uploaded: {
        user: { id: user?._id, name: user?.name, email: user?.email },
        date: new Date(),
      },
    });
    updateVisa({ ...visa, visaDocs: updatedVisaDocs });
  };

  const handleFileUpdate = async (file, fieldName, rowId) => {
    const fileData = file.target.files[0];
    if (!fileData) return;

    try {
      await visaFileUploading(fileData);

      const updatedVisaDocs = { ...visa.visaDocs };

      if (!updatedVisaDocs[fieldName]) {
        updatedVisaDocs[fieldName] = [];
      }

      // Find the specific document to update based on the rowId
      const docIndex = updatedVisaDocs[fieldName].findIndex((doc) => doc.id === rowId);

      if (docIndex !== -1) {
        // Define the new document object
        const newDoc = {
          id: updatedVisaDocs[fieldName][docIndex].id, // Keep the same ID to update the document
          fileName: fileData.name,
          uploaded: {
            user: { id: user?._id, name: user?.name, email: user?.email },
            date: new Date(),
          },
          revert: {
            value: false,
            comment: "",
            assignTo: "",
            docName: "",
            user: { id: "", email: "", name: "" },
            date: "",
          },
        };

        // Replace the specific document
        updatedVisaDocs[fieldName][docIndex] = newDoc; // Use docIndex to replace

        // Update visa object with the modified visaDocs
        await updateVisa({ ...visa, visaDocs: updatedVisaDocs }); // Ensure that this is awaited if it's async
        // console.log("File updated successfully in visaDocs");
      } else {
        console.error("Document not found for updating.");
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const submitRevert = () => {
    setOpenRevertModal(false);

    // Step 1: Update the specific document within `visaDocs`
    const updatedVisaDocs = { ...visa.visaDocs }; // Create a shallow copy of `visaDocs`
    const fieldDocs = updatedVisaDocs[selectedFileName.fieldName];

    if (fieldDocs) {
      const doc = fieldDocs.find((doc) => doc.fileName === selectedFileName.fileName);
      if (doc) {
        const assignTo = doc?.uploaded?.user?.name;
        // Update the `revert`, `viewed`, and `verify` properties of the document
        doc.revert = {
          value: true,
          comment: revertMessage,
          assignTo: assignTo,
          docName: selectedFileName.fileName,
          user: { id: user._id, email: user.email, name: user.name },
          date: new Date(),
        };
        doc.viewed = {
          user: { id: user._id, email: user.email, name: user.name },
          value: false,
        };
        doc.verify = {
          user: { id: user._id, email: user.email, name: user.name },
          value: false,
        };
      } else {
        console.error("File not found in visaDocs");
        return;
      }
    } else {
      console.error("Field not found in visaDocs");
      return;
    }

    // Construct only the revert object to update
    const revert = {
      value: true,
      docFieldName: selectedFileName.fieldName,
      docName: selectedFileName.fileName,
      user: { id: user._id, email: user.email, name: user.name },
      assignTo: fieldDocs?.find((doc) => doc.fileName === selectedFileName.fileName)?.uploaded?.user?.name,
      comment: revertMessage,
      date: new Date(),
    };

    updateVisa(
      {
        revert: revert,
        visaDocs: updatedVisaDocs,
      },
      visa._id,
    )
      .then(() => {
        console.log("Revert submitted successfully");
        setRevertMessage("Visa revert updated successfully");
      })
      .catch((error) => {
        console.error("Error updating revert:", error);
        setRevertMessage("Error submitting visa revert");
      });
  };

  const handleVisaDocuments = () => {
    const updatedVisaDocs = { ...visa.visaDocs };
    updatedVisaDocs["submittedBy"] = [
      {
        id: Date.now().toString(),
        user: { id: user?._id, name: user?.name, email: user?.email },
        date: new Date(),
      },
    ];

    updateVisa({ ...visa, visaDocuments: updatedVisaDocs });
  };

  return {
    openViewModal,
    setOpenViewModal,
    openRevertModal,
    setOpenRevertModal,
    revertMessage,
    setRevertMessage,
    selectedFileName,
    documentChecklistName,
    handleView,
    handleRevert,
    handleVerify,
    handleFileUpload,
    handleFileUpdate,
    submitRevert,
    handleVisaDocuments,
  };
}
