import React from 'react';
import { Box, Typography, Stack } from '@mui/material';
import { useDocumentCheckListViewModel } from './DocumentCheckListViewModel';
import DocumentTable from './DocumentTableView';
import FileIframeViewModal from './FileIFrameView';
import RevertModal from './RevertModalView';

const DocumentCheckList = ({ documents, visa, users, updateVisa, visaFileUploading }) => {
  const viewModel = useDocumentCheckListViewModel(visa, updateVisa, visaFileUploading);
  return (
    <Box sx={{ padding: 5, borderRadius: 2 }}>
      <Typography variant="h3" gutterBottom>
        {viewModel.documentChecklistName}
      </Typography>
      <Stack spacing={2}>
        <DocumentTable
          documents={documents}
          visa = {visa}
          visaDocs={visa?.visaDocs}
          handleView={viewModel.handleView}
          handleRevert={viewModel.handleRevert}
          users = {users}
          handleVerify={viewModel.handleVerify}
          handleFileUpload={viewModel.handleFileUpload}
          handleFileUpdate={viewModel.handleFileUpdate}
          handleVisaDocuments={viewModel.handleVisaDocuments}
        />
      </Stack>
      <FileIframeViewModal
        open={viewModel.openViewModal}
        onClose={() => viewModel.setOpenViewModal(false)}
        selectedFileName={viewModel.selectedFileName}
        visa={visa}
      />
      <RevertModal
        open={viewModel.openRevertModal}
        onClose={() => viewModel.setOpenRevertModal(false)}
        revertMessage={viewModel.revertMessage}
        setRevertMessage={viewModel.setRevertMessage}
        
        // assignTo={viewModel.assignTo}
        // setAssignTo={viewModel.setAssignTo}
        submitRevert={viewModel.submitRevert}
        visa={visa}
      />
    </Box>
  );
};

export default DocumentCheckList;